import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm.component';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as StyledCommon from '../page.styled';
import { Header, Footer } from '../includes';
import * as Styled from './home.styled';
import { Redirect } from 'react-router-dom';
import { PATHS } from '../../constants';
import { STRIPE_PUBLISH_KEY } from '../../config/config';

type Props = {
    Information: Function
};

class Checkout extends Component<Props> {
    render() {
        if(this.props.Information.length<=0){
            return <Redirect to={PATHS.HOME} />;
       }
        return (
            <StyledCommon.Wrapper>
                <Header />
                <Styled.BannerHome className="banner-bg">
                    <StyledCommon.Container className="pageContainer">
                        <Styled.BannerText className="pink-bg">
                            <Styled.BannerTextH1>Payment</Styled.BannerTextH1>
                        </Styled.BannerText>
                    </StyledCommon.Container>
                </Styled.BannerHome>
                <div className="pageContainer pb-50">
                    <StyledCommon.Container className="main-wrapper">
                        <StyledCommon.Wrapper>
                            <StyledCommon.HeadingTag>
                                You can book your appointment below! To not miss out on coupon code and loyalty points please <a target ="_blank" href='https://www.mybeautysquad.com/beautyapp' className="download-link">download our app</a> to book.
                            </StyledCommon.HeadingTag>
                        </StyledCommon.Wrapper>
                        <Styled.NotSure className="mb-30">
                            <StripeProvider
                                stripe={window.Stripe(STRIPE_PUBLISH_KEY, { betas: ['payment_intent_beta_3'] })}
                            >
                                <div className="example">
                                    <Elements>
                                        <CheckoutForm />
                                    </Elements>
                                </div>
                            </StripeProvider>
                        </Styled.NotSure>
                    </StyledCommon.Container>
                </div>
                <Footer />
            </StyledCommon.Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    categoryList: '',
    isLoading: '',
    Information: state.content.getInformation
});

export default connect(
    mapStateToProps,
    {}
)(
    reduxForm({
        form: 'bookingForm'
    })(Checkout)
);
