const required = value => (value ? undefined : 'Required');

const minLength = max => value =>
    value && value.length < max ? `Password Length must be ${max} characters or more` : undefined;
const minLength6 = minLength(6);

const maxLengthExpiryPage = max => value =>
    value && value.length > max ? `Length must be less than ${max}` : undefined;
const maxLength250 = maxLengthExpiryPage(250);

const onlyChar = value => (value && !/^[a-zA-Z ]*$/i.test(value) ? 'Please insert only characters' : undefined);

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

const addressValidation = value => (value && !/^[a-zA-Z0-9\s,'.-]*$/i.test(value) ? 'Invalid Address' : undefined);

const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export { required, minLength6, email, maxLength250, onlyChar, addressValidation, number };
