// @flow

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';
import { PATHS } from './constants';
import Error404 from './pages/error404';
import Thankyou from './pages/thankyou';
import { Home, Booking, Checkout, Thank } from './pages/home';

/*
 TYPES
*/

type Props = {};

class App extends Component<Props> {
    render () {
        return (
            <Router>
                <Switch>
                    <Route exact path={PATHS.HOME} component={Home} />
                    <Route exact path={PATHS.THANKYOU} component={Thankyou} />

                    <Route exact path={PATHS.BOOKING} component={Booking} />
                    <Route exact path={PATHS.CHECKOUT} component={Checkout} />
                    <Route exact path={PATHS.THANK} component={Thank} />
                    <Route component={Error404} />
                </Switch>
            </Router>
        );
    }
}

export default App;
