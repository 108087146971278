import * as styledComponents from 'styled-components';
import { Link } from 'react-router-dom';

const { default: styled } = styledComponents;

export const BannerHome = styled.div`
  display: block;
  padding: 0;
  margin-bottom: 15px;
`;

export const BannerText = styled.div`
  display: block;
  color: #000;
  text-align: center;
`;

export const BannerTextH1 = styled.h1`
  line-height: 1.17;
  letter-spacing: -0.45px;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  background: #fff;
  font-size: 35px;
  position: relative;
  z-index: 9;
  @media (max-width: 400px) {
    font-size: 24px;
  }
`;

export const Logo = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const ServiceLocH2 = styled.h2`
  margin-bottom: 5px;
`;

export const ServiceLocP = styled.p``;

export const CircleS1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const CircleS1Anchor = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  margin-right: 8px;
  margin-bottom: 15px;
  &:last-child {
    margin-right: 0;
  }
  & span {
    color: #ffb6cf;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.25;
    text-align: center;
    width: 60px;
    height: 60px;
    background: rgba(233, 233, 233, 0.5);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      line-height: 1.33;
      text-align: center;
      background: transparent;
      width: auto;
      height: inherit;
    }
  }
`;

export const CircleS1Icon = styled.div`
  display: none;
`;

export const CircleS1Span = styled.span``;

export const ListBanner = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100% !important;
`;

export const ListBannerImg = styled.img`
  border-radius: 5px;
  width: 100% !important;
  height: auto !important;
`;

export const ListContent = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  padding-left: 19px;
  padding-right: 24px;
`;

export const ListTextH3 = styled.h3`
  font-family: 'acumin-pro', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.24;
  color: #ffffff;
`;

export const ListTextH4 = styled.h4`
  font-family: 'acumin-pro', sans-serif;
  font-size: 17px;
  font-weight: normal;
  line-height: 1.24;
  color: #ffffff;
`;

export const ListArrowAnchor = styled.div`
  font-family: FontAwesome5Pro;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
  color: #f56f23;

  border-radius: 22.5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListArrowIcon = styled.i`
  font-size: 1.5em;
`;

export const ListIconAnchor = styled.div`
  background: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #fff;
  }
`;

export const NotSure = styled.div`
  border: 1px solid #ffccdd;
  background: rgba(255, 245, 248, 0.5);
  padding: 25px 30px;

  svg {
    color: #ffb6cf;
    margin-right: 10px;
    vertical-align: baseline;
    margin-top: 3px;
  }
  @media (max-width: 400px) {
    padding: 15px;
  }
`;

export const NotSureH3 = styled.h3`
  color: #545454;
`;

export const NotSureP = styled.p`
  color: #4a4a4a;
`;

export const NotSureUl = styled.ul`
  list-style: none;
  margin: 0 0 10px 0;
  padding: 5px 0;
`;

export const NotSureLi = styled.li`
  position: relative;
  padding-left: 3px;
  margin-top: 7px;
  display: flex;
  margin-bottom: 8px;
  color: #69696;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    color: #69696c;
    width: 25px;
    height: 25px;
  }
`;

export const NotSureAnchor = styled.button`
  // color: #fff;
  //   border: 2px solid #314575;
  //   padding: 15px 10%;
  //   text-transform: uppercase;
  //   display: inline-block;
  //   background: #314575;
  //   box-shadow: 0px 3px 0 #e0dfde;
  //   border-radius: 30px;
`;
