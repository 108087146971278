// @flow

import React, { Component } from 'react';

import * as Styled from '../page.styled';
import * as image from '../../images';


/*
 TYPES
*/

type Props = {};

class Footer extends Component<Props> {
    render() {
        return (
            <Styled.BottomBarContainer>
                <div className="footer-link">
                    If you have any issues please contact us<Styled.InnerAnchor href="tel:0203 657 7033">0203 657 7033</Styled.InnerAnchor>or<Styled.InnerAnchor onClick={() => (window.location.href = 'mailto:bookings@mybeautysquad.com')}>bookings@mybeautysquad.com</Styled.InnerAnchor>
                </div>
            </Styled.BottomBarContainer>
        );
    }
}

export default Footer;