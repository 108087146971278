import {
    COUNTRY_NAME,
    APPOINTMENT,
    SET_LOADER,
    CONTACT_FORM,
    SERVICES,
    TIME_SLOT,
    PEAK_TIME,
    PAYMENT_SUCCESS,
    PAYMENT_FAILED,
    CHECK_EMAIL,
    SELECTED_SERVICES,
    SELECTED_ACTUALPRICE,
    SELECTED_PEAKPRICE,
    RESET_EMAIL,
    TOTALPRICE
} from '../actions/types';

const INITIAL_STATE = {
    pageDataTerms: [],
    pageDataPrivacy: [],
    response: [],
    getInformation: [],
    countryName: [],
    getAppointment: [],
    timeSlot: [],
    peakTime: [],
    paymentSuccess: [],
    paymentFailed: [],
    checkEmail: [],
    isLoading: false,
    services: [],
    selectedServices:[],
    peakPrice:[],
    actualPrice:[],
    totalPrice:[],
    quantity:1
};

export default (state = INITIAL_STATE, action) => {console.log(action.payload)
    switch (action.type) {
        case SET_LOADER:
            return { ...state, pageDataTerms: action.payload, isLoading: false };
        case COUNTRY_NAME:
            return { ...state, countryName: action.payload, isLoading: false };
        case CONTACT_FORM:
            return { ...state, getInformation: action.payload, isLoading: false };
        case APPOINTMENT:
            return { ...state, getAppointment: action.payload, isLoading: false };
        case SERVICES:
            return { ...state, services: action.payload, isLoading: false };
        case TIME_SLOT:
            return { ...state, timeSlot: action.payload, isLoading: false };
        case PEAK_TIME:
            return { ...state, peakTime: action.payload, isLoading: false };
        case PAYMENT_SUCCESS:
            return { ...state, paymentSuccess: action.payload, isLoading: false };
        case PAYMENT_FAILED:
            return { ...state, paymentFailed: action.payload, isLoading: false };
        case CHECK_EMAIL:
            return { ...state, checkEmail: action.payload, isLoading: false };
        case SELECTED_SERVICES:
            return { ...state, selectedServices: action.payload, isLoading: false };
        case SELECTED_ACTUALPRICE:
            return { ...state, actualPrice: action.payload, isLoading: false };
        case SELECTED_PEAKPRICE:
            return { ...state, peakPrice: action.payload, isLoading: false };
        case RESET_EMAIL:
            return { ...state, checkEmail: action.payload, isLoading: false };
        case TOTALPRICE:
            return { ...state, totalPrice: action.payload, isLoading: false };
        default:
            return state;
    }
};
