// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoaderScreen, Header } from '../includes';
import * as Styled from '../home/home.styled';
import * as StyledCommon from '../page.styled';

import * as image from '../../images';

/*
 TYPES
*/

type Props = {
  history: Object,
  categoryList: Object,
  fetchCategory: Function,
  setLoader: Function,
  isLoading: boolean
};

class Error404 extends Component<Props> {
    render () {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        return (
            <StyledCommon.Wrapper>
                <Header />
                <Styled.BannerHome className="banner-bg">
                    <StyledCommon.Container className="pageContainer">
                        <Styled.BannerText className="pink-bg">
                            <Styled.BannerTextH1>Ooops!</Styled.BannerTextH1>
                        </Styled.BannerText>
                    </StyledCommon.Container>
                </Styled.BannerHome>
                <div className="pageContainer pb-50">
                    <StyledCommon.Container className="main-wrapper">
                        <StyledCommon.Wrapper>
                            <StyledCommon.Banner404>
                                <img src={image.error404} />
                            </StyledCommon.Banner404>
                            <StyledCommon.HeadingTag>
                You can book for your appointment below. Don&apos;t miss out! For coupon codes, loyalty points and more
                please book via our app!
                            </StyledCommon.HeadingTag>
                        </StyledCommon.Wrapper>
                    </StyledCommon.Container>
                </div>
            </StyledCommon.Wrapper>
        );
    }
}

export default connect()(Error404);
