// @flow

import React, { Component } from 'react';

import * as Styled from '../page.styled';
import * as image from '../../images';


/*
 TYPES
*/

type Props = {};

class Header extends Component<Props> {
    render () {
        return (
            <Styled.BottomBarContainer>
                <div className="logo">
                    <a href="#">
                        <img src={image.siteLogo} />
                    </a>
                </div>
            </Styled.BottomBarContainer>
        );
    }
}

export default Header;
