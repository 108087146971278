import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { LoaderScreen, Header, Footer } from '../includes';
import * as Styled from './home.styled';
import * as StyledCommon from '../page.styled';
import { setLoader, bookingForm, checkEmail, resetEmail } from '../../actions';

import { renderInput } from '../../constants/redux-form';
import { maxLength250, required, email, onlyChar, number } from '../../constants/validation';
import { PATHS } from '../../constants';
import Recaptcha from 'react-recaptcha';
import { RECAPTCHA_PUBLIC_KEY } from '../../config/config';


/*
 TYPES
*/

type Props = {
    history: Object,
    categoryList: Object,
    setLoader: Function,
    isLoading: boolean,
    handleSubmit: Function,
    bookingForm: Function,
    checkEmail: Function,
    Information: Function,
    appointmentData: Function
};

class Booking extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            emailexist: '',
            errorMessage: '',
            showPopup: false,
            isChecked: false,
            requiredChaeckbox: '',
            // isVerified: false,
            captchaMessage: '',
            captchaResponse: '',
            submitted:false
        };
        this.togglePopup.bind(this);
        this.toMain = React.createRef();
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    recaptchaLoaded() {
        console.log('capcha successfully loaded');
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    componentDidMount() {
        this.props.setLoader(true);
        this.props.resetEmail();
    }

    closePopup = () => {
        this.togglePopup(this);
    };

    popup = () => (
        <div className="popup" ref={this.toMain}>
            <div className="popup_inner">
                <StyledCommon.Wrapper>



                    <Header />
                    <Styled.BannerHome className="banner-bg">

                        <StyledCommon.Container className="pageContainer">
                            <Styled.BannerText className="pink-bg">
                                <a onClick={this.closePopup} className="close-btn">
                                    Back
                    </a>
                                <Styled.BannerTextH1>Terms & Conditions</Styled.BannerTextH1>
                            </Styled.BannerText>
                        </StyledCommon.Container>
                    </Styled.BannerHome>
                    <div className="pageContainer pb-20">
                        <StyledCommon.Container className="main-wrapper">
                            <StyledCommon.Wrapper>
                                <StyledCommon.HeadingTag>
                                    You can book your appointment below! To not miss out on coupon code and loyalty points please <a target="_blank" href='https://www.mybeautysquad.com/beautyapp' className="download-link">download our app</a> to book.
                            </StyledCommon.HeadingTag>
                            </StyledCommon.Wrapper>
                            <div className="popup-content">
                            <p>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE OR MAKING A BOOKING:<br />
                                <br />
                                1. INFORMATION ABOUT US<br />
                                <br />
                                1.1 www.mybeautysquad.com (&ldquo;Site&rdquo;) is a site operated by My Beauty Squad Limited (&quot;We&quot;). We are registered in England and Wales under company number 0993156 and have our registered office at Berkeley Square, Mayfair,&nbsp;London. Our main trading address is our registered address.<br />
                                <br />
                                1.2 We are a limited company.<br />
                                <br />
                                2. BOOKING TERMS AND CONDITIONS<br />
                                <br />
                                <strong>2.1 Making an order</strong><br />
                                <br />
                                2.1.1 My Beauty Squad Limited allows you to book and pay for a broad range of treatments which are provided by freelance therapists handpicked by My Beauty Squad Limited. Additionally, My Beauty Squad Limited allows you to purchase gift certificates which can be used to pay for, or make a payment towards, bookings made through My Beauty Squad Limited (&ldquo;Gift Certificates&rdquo;)<br />
                                <br />
                                2.1.2 My Beauty Squad Limited does not provide any of the treatments directly and is acting as commercial agent of the freelance therapists in relation to the taking of payment and arranging of the bookings only. No contractual relationship exists or will exist between you and My Beauty Squad Limited in relation to the provision of treatments booked through the Site and My Beauty Squad Limited does not accept any liability whatsoever for any loss or damage you suffer or may suffer arising out of or in connection with any breach of your contract with the freelance therapist on the part of the freelance therapist.<br />
                                <br />
                                2.1.3 Gift Certificates, Credit Vouchers &amp; Promo Codes are issued by My Beauty Squad Limited and are valid for 6 months from being issued as further detailed in clause 2.2.4 below. Gift Certificates are redeemable against bookings made through our Site, i.e. they can be used to reduce the purchase price payable for any of the treatments offered by the freelance therapists on our Site by the equivalent amount on the Gift Certificate. Any outstanding amounts on your Gift Certificate remain on your Gift Certificate for future use, providing that this is redeemed with 6 months from the original Gift Certificate being issued. Gift Certificates have no monetary value and can only be redeemed against bookings through our Site and not for cash. Any complimentary treats offered by My Beauty Squad are redeemable at the time of the booking upon to receiving a minimum of 48 hours (2 days) notice prior to the booking date. &nbsp;<br />
                                <br />
                                2.1.4 Our shopping pages will guide you through the steps you need to take to place an order with us. Our order process allows you to check and amend any errors before submitting your order to us. Please take the time to read and check your order at each page of the order process. My Beauty Squad is not responsible for user order errors.</p>

                                <p><br />
                                <strong>2.2 How the contract is formed</strong><br />
                                <br />
                                2.2.1 After you place an order, you will receive an e-mail from us acknowledging that we have received your order. Please note that this does not mean that your order has been accepted. Acceptance of your order will take place as described in Clause 2.2.2 and 2.2.3.<br />
                                <br />
                                2.2.2 We will confirm acceptance to you by sending you an e-mail confirming that your order has been accepted (&ldquo;Order Confirmation&rdquo;). As we act as commercial agent for the freelance therapists, sending of the Order Confirmation will form the contract between you and the freelance therapist.<br />
                                <br />
                                2.2.3 Sending of the Order Confirmation also forms a contract between you and us in relation to the booking and payment process and in relation to Gift Certificates where your order was for a Gift Certificate. This contract is made on these booking terms and conditions at Clause 2.<br />
                                <br />
                                2.2.4 Where you have purchased a Gift Certificate, we will issue the Gift Certificate to you by email within [24] hours after we have sent you the Order Confirmation.<br />
                                <br />
                                2.3 Payment<br />
                                <br />
                                2.3.1 You can only pay for your booking or Gift Certificate using debit card or credit card. We accept the following cards Visa, Mastercard and American Express.<br />
                                <br />
                                2.3.2 Payment for your booking is in advance. We will not charge your debit card or credit card until we have confirmed your booking by sending you an Order Confirmation.<br />
                                <br />
                                <strong>2.4 Re-scheduling or cancelling and appointment</strong><br />
                                <br />
                                2.4.1 If you wish to re-schedule an appointment after receipt of the Order Confirmation, please contact us and we will try to assist you in arranging a new time and/or date with the freelance therapist. We can also make a available a Credit Voucher to be used in a future date. Please note that we will be unable to assist with arranging a new time and/or date or Credit Voucher with the freelance therapist should your request to re-schedule your appointment be received by us less than 24 hours prior to your scheduled appointment.</p>

                                <p>2.4.1.1 If you wish to re-schedule or cancel an appointment pre-confirmed via deposit please do so no later than 24 hours prior to the&nbsp;appointment&nbsp;date to receive a full refund amount. Bookings confirmed via deposit and cancelled after the cancellation notice period will not be refunded. Please note the transaction cost is non-refundable.<br />
                                <br />
                                2.4.2 If you are a consumer, you have a legal right to cancel your contract with the freelance therapist, or your contract with My Beauty Squad Limited in respect of Gift Certificates or Credit vouchers, within 14 days of us sending you the Order Confirmation (&ldquo;Cancellation Period&rdquo;). This means that if you change your mind or decide for any other reason that you do not want the treatment/ Gift Certificate/ Credit Voucher, you can notify us of your decision to cancel the contract between you and the freelance therapist, or your contract with My Beauty Squad Limited in respect of certificates/vouchers and receive a refund. Please note that payment for appointments paid for by Gift Certificate/Credit Voucher will be refunded to the original method used in payment of the appointment [and the 6 months period in which you can redeem the Gift Certificate will continue to run from the date of the refund]. Advice about your legal right to cancel the Contract is available from your local Citizens&#39; Advice Bureau or Trading Standards office.<br />
                                <br />
                                2.4.3 Your legal right to cancel your contract with the freelance therapist, or your contract with My Beauty Squad Limited in respect of Gift Certificates/ Credit Vouchers, starts from the date of the Order Confirmation (the date on which we e-mail you to confirm our acceptance of your order), which is when the contract is formed. Should you wish to cancel your appointment, you need to contact us confirming that you wish to cancel your contract prior to the expiry of the Cancellation Period. To meet the cancellation deadline, it is sufficient for you to send us your notice that you wish to cancel the contract before the Cancellation Period has expired and we will provide you with a refund. The easiest way to contact us is by email at bookings@mybeautysquad.com or Telephone at 020 3657 7033. Please note that should you cancel your appointment after expiry of the Cancellation Period then, at our discretion, we may charge a booking cancelation fee of 100% of the appointment charge.<br />
                                <br />
                                2.4.4 When you undergo a treatment by the freelance therapist, you are agreeing that the freelance therapist starts the treatment before the expiry of the Cancellation Period and you have accordingly made an express request for the early supply of services (&ldquo;Express Request&rdquo;). You acknowledge that where you have made an Express Request during the Cancellation Period, you lose your cancellation right once the treatment has been fully performed and you will be required to pay for the treatment carried out.<br />
                                <br />
                                2.4.5 When you redeem your Gift Certificate or Credit Voucher in payment or part payment of any treatment booked through our Site within the Cancellation Period and have undergone the treatment before expiry of the Cancelation Period, it is deemed that you have made an Express Request as further detailed at clause 2.4.4 of these terms and conditions and you acknowledge that where you have made such an Express Request you lose your cancellation right once the treatment has been fully performed and you will not be able to obtain a refund for the amount used from the Gift Certificate in respect of the treatment received.<br />
                                <br />
                                2.4.6 If at the time of your appointment, our beautician cannot gain access into your location then there is a tolerance period of 15 minutes, after which the appointment will be considered cancelled with no possibility of a refund.</p>

                                <p>2.4.7 We reserve the right to cancel an appointment if before or during a service, the client is deemed to be acting in an unreasonable manner and/or if the client is deemed to be verbally or physically abusive towards the beautician. In such instances the contract between parts is revoked and we retain the right to decide if payment for the service(s) should be refunded.&nbsp;</p>

                                <p>2.4.8 My Beauty Squad ltd has no further obligations to replace or perform the service(s) when a contract is revoked by either parties. We can provide a full refund for remaining service(s) not performed. This deems the service as cancelled, removing any further obligation on My Beauty Squad to complete the service.&nbsp;</p>

                                <p><br />
                                2.4.9 &nbsp;If you wish to contact us for any other reason, including should you&nbsp;have any complaints, you can contact us by telephoning our customer service team at 020 3657 7033 or by&nbsp;</p>

                                <p>e-mailing us at bookings@mybeautysquad.com. After a service is deemed performed&nbsp;you have 48 hours after completion to contact My Beauty Squad regarding complaints providing evidence to suffice your claim. After the complaint period My Beauty Squad has no obligation to respond or refund your complaint.<br />
                                <br />
                                <strong>2.5 Before your appointment</strong><br />
                                <br />
                                2.5.1 It is your responsibility to discuss any medical or health related issues in advance of the treatment with your freelance therapist. If you are uncertain about whether or not a medical condition or health related issue is affected by the treatment you must seek medical advice from your doctor. Certain conditions will mean that you cannot have certain treatments and it is at the freelance therapist&rsquo;s discretion to decide whether or not it is safe for you to have the treatment. Subject to clause 2.7, if you fail to disclose any medical condition or health related issue to the freelance therapist, neither My Beauty Squad Limited nor the freelance therapist shall be liable for any injury, loss or damage resulting from the treatment which could have been avoided had you disclosed the medical condition or health related issue before receiving the treatment.<br />
                                <br />
                                2.5.2 The freelance therapist may arrive up to 15 minutes before the appointment in order to set up any necessary equipment for the appointment. Please make sure to provide reasonable work conditions with access to tables, chair and water.<br />
                                <br />
                                2.5.3 If you are late for your appointment your appointment time will be cut short if necessary. Please note our&nbsp;tolerance is 15 minutes.<br />
                                <br />
                                <strong>2.6 Subscription service</strong><br />
                                <br />
                                2.6.1 My Beauty Squad Limited provides the possibility of a subscription service for hair appointments. The appointments are non-cumulative and non-transferrable.&nbsp;A monthly charge will be automatically applied until you place a cancellation request via bookings@mybeautysquad.com.</p>

                                <p>2.6.2 Each payment taken is valid for four appointments per month on selected days and times. If the appointments are not used during the&nbsp;period&nbsp;specified the same will be deemed as out of date and you will no longer be able to use the&nbsp;booking.&nbsp;​</p>

                                <p>2.6.3 Once one of the four monthly appointments is scheduled the remaining bookings for the monthly period are non-refundable. You may cancel the subscription and not be&nbsp;charged&nbsp;for the following period.&nbsp;</p>

                                <p><br />
                                <strong>2.7 Our liability</strong><br />
                                <br />
                                2.7.1 If we fail to comply with these booking terms and conditions, we are responsible for loss or damage you suffer that is a direct and foreseeable result of our breach of these booking terms and conditions or our negligence. Loss or damage is foreseeable if it is an obvious consequence of our breach or if it was contemplated by you and us at the time we entered into this contract.<br />
                                <br />
                                2.7.2 We do not in any way exclude or limit our liability for:<br />
                                <br />
                                (a) death or personal injury caused by our negligence; or<br />
                                <br />
                                (b) fraud or fraudulent misrepresentation.<br />
                                <br />
                                <strong>2.8 Events outside our control</strong><br />
                                <br />
                                2.8.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these booking terms and conditions that is caused by an Event Outside Our Control. An Event Outside Our Control is defined below in Clause 2.8.2 below. If you wish to pay a private travel fee to avoid delays, we make this available to the stylist.<br />
                                <br />
                                2.8.2 An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public or private telecommunications networks or impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport.<br />
                                <br />
                                2.8.3 If an Event Outside Our Control takes place that affects the performance of our obligations under these terms:<br />
                                <br />
                                (a) we will contact you as soon as reasonably possible; and<br />
                                <br />
                                (b) our obligations under these booking terms and conditions will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control.<br />
                                <br />
                                3. CHANGES TO THESE TERMS<br />
                                <br />
                                3.1 We may revise these terms and conditions at any time by amending this page.<br />
                                <br />
                                3.2 Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>

                                <p>3.3 We may revise these terms as they apply to your order from time to time to reflect changes in relevant laws and regulatory requirements<br />
                                <br />
                                4. CHANGES TO OUR SITE<br />
                                <br />
                                4.1 We may update our site from time to time, and may change the content at any time. However, please note that any of the content on our site may be out of date at any given time, and we are under no obligation to update it.<br />
                                <br />
                                4.2 We do not guarantee that our site, or any content on it, will be free from errors or omissions.<br />
                                <br />
                                5. ACCESSING OUR SITE<br />
                                <br />
                                5.1 Our site is made available free of charge.<br />
                                <br />
                                5.2 We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. Access to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our site without notice. We will not be liable to you if for any reason our site is unavailable at any time or for any period.<br />
                                <br />
                                5.3 You are responsible for making all arrangements necessary for you to have access to our site.<br />
                                <br />
                                5.4 You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.<br />
                                <br />
                                5.5 Our site is directed to people residing in the United Kingdom. We do not represent that content available on or through our site is appropriate or available in other locations. We may limit the availability of our site or any service or product described on our site to any person or geographic area at any time. If you choose to access our site from outside the United Kingdom, you do so at your own risk.<br />
                                <br />
                                6. YOUR ACCOUNT AND PASSWORD<br />
                                <br />
                                6.1 If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.<br />
                                <br />
                                6.2 We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.<br />
                                <br />
                                6.3 If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at info@mybeautysquad.com<br />
                                <br />
                                7. INTELLECTUAL PROPERTY RIGHTS<br />
                                <br />
                                7.1 We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.<br />
                                <br />
                                7.2 You may print off one copy, and may download extracts, of any page(s) from our site for your personal use.<br />
                                <br />
                                7.3 You must not modify any paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.<br />
                                <br />
                                7.4 Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.<br />
                                <br />
                                7.5 You must not use any part of the content on our site for commercial purposes.<br />
                                <br />
                                7.6 If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.<br />
                                <br />
                                8. NO RELIANCE ON INFORMATION<br />
                                <br />
                                8.1 The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.<br />
                                <br />
                                8.2 Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.<br />
                                <br />
                                9. LIMITATION OF OUR LIABILITY<br />
                                <br />
                                9.1 Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.<br />
                                <br />
                                9.2 To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied.<br />
                                <br />
                                9.3 We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:<br />
                                <br />
                                9.3.1 use of, or inability to use, our site; or<br />
                                <br />
                                9.3.2 use of or reliance on any content displayed on our site.<br />
                                <br />
                                9.4 If you are a business user, please note that in particular, we will not be liable for:<br />
                                <br />
                                9.4.1 loss of profits, sales, business, or revenue;<br />
                                <br />
                                9.4.2 business interruption;<br />
                                <br />
                                9.4.3 loss of anticipated savings;<br />
                                <br />
                                9.4.4 loss of business opportunity, goodwill or reputation; or<br />
                                <br />
                                9.4.5 any indirect or consequential loss or damage.<br />
                                <br />
                                9.5 If you are a consumer user, please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.<br />
                                <br />
                                9.6 We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.<br />
                                <br />
                                9.7 We assume no responsibility for the content of websites linked on our site. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.<br />
                                <br />
                                9.8 We are not liable for any photography taken of the service to which you have given consent. My Beauty Squad ltd is not responsible for the images used on freelancer&rsquo;s social media platforms. It is your responsibility to allow or deny photography to be taken and/or be used on social media platforms.</p>

                                <p>&nbsp;</p>

                                <p>9.9 Different limitations and exclusions of liability will apply to liability arising as a result of making a booking through our Site, which will be set out in our Bookings Terms and Conditions at Clause 2 above www.mybeautysquad.com.<br />
                                <br />
                                10. UPLOADING CONTENT TO OUR SITE<br />
                                <br />
                                10.1 Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must comply with the content standards set out at Clause 10.2.<br />
                                <br />
                                10.2 Content standards<br />
                                <br />
                                10.2.1 The content standards apply to any and all material which you contribute to our site (contributions), and to any interactive services associated with it.<br />
                                <br />
                                10.2.2 You must comply with the spirit and the letter of the following standards. The standards apply to each part of any contribution as well as to its whole.<br />
                                <br />
                                10.2.3 Contributions must:<br />
                                <br />
                                (a) be accurate (where they state facts);<br />
                                <br />
                                (b) be genuinely held (where they state opinions); and<br />
                                <br />
                                (c) comply with applicable law in the UK and in any country from which they are posted.<br />
                                <br />
                                10.2.4 Contributions must not:<br />
                                <br />
                                (a) contain any material which is defamatory of any person.<br />
                                <br />
                                (b) contain any material which is obscene, offensive, hateful or inflammatory.<br />
                                <br />
                                (c) promote sexually explicit material.<br />
                                <br />
                                (d) promote violence.<br />
                                <br />
                                (e) promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.<br />
                                <br />
                                (f) infringe any copyright, database right or trade mark of any other person.<br />
                                <br />
                                (g) be likely to deceive any person.<br />
                                <br />
                                (h) be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.<br />
                                <br />
                                (i) promote any illegal activity.<br />
                                <br />
                                (j) be threatening, abuse or invade another&rsquo;s privacy, or cause annoyance, inconvenience or needless anxiety.<br />
                                <br />
                                (k) be likely to harass, upset, embarrass, alarm or annoy any other person.<br />
                                <br />
                                (l) be used to impersonate any person, or to misrepresent your identity or affiliation with any person.<br />
                                <br />
                                (m) give the impression that they emanate from us, if this is not the case.<br />
                                <br />
                                (n) advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.<br />
                                <br />
                                10.3 You warrant that any such contribution does comply with the standards set out in Clause 10.2, and you will be liable to us and indemnify us for any breach of that warranty. If you are a consumer user, this means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.<br />
                                <br />
                                10.4 We will determine, in our discretion, whether there has been a breach of these terms and conditions through your use of our site. When a breach of this policy has occurred, we may take such action as we deem appropriate.<br />
                                <br />
                                10.5 Failure to comply with these terms and conditions constitutes a material breach of the terms of use upon which you are permitted to use our site, and may result in our taking all or any of the following actions:<br />
                                <br />
                                10.5.1 immediate, temporary or permanent withdrawal of your right to use our site;<br />
                                <br />
                                10.5.2 immediate, temporary or permanent removal of any posting or material uploaded by you to our site;<br />
                                <br />
                                10.5.3 issue of a warning to you;<br />
                                <br />
                                10.5.4 legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach;<br />
                                <br />
                                10.5.5 further legal action against you;<br />
                                <br />
                                10.5.6 disclosure of such information to law enforcement authorities as we reasonably feel is necessary; and/or<br />
                                <br />
                                10.5.7 we exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.<br />
                                <br />
                                10.6 Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of our Site a limited licence to use, store and copy that content and to distribute and make it available to third parties. The rights you license to us are described in the next paragraph (Rights you licence).<br />
                                <br />
                                10.7 We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.<br />
                                <br />
                                10.8 We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of our site.<br />
                                <br />
                                10.9 We have the right to remove any posting you make on our site if, in our opinion, your post does not comply with the content standards set out at clause 10.2 or for any other reason.<br />
                                <br />
                                10.10 The views expressed by other users on our site do not represent our views or values.<br />
                                <br />
                                10.11 You are solely responsible for securing and backing up your content.<br />
                                <br />
                                11. RIGHTS YOU LICENCE<br />
                                <br />
                                11.1 When you upload or post content to our site, you grant the following licenses to My Beauty Squad Limited:<br />
                                <br />
                                11.1.1 an indefinite perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform the content generated by you on this Site; and<br />
                                <br />
                                11.1.2 An indefinite perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use the content generated by you to promote the Site and its services.<br />
                                <br />
                                12. VIRUSES<br />
                                <br />
                                12.1 We do not guarantee that our site will be secure or free from bugs or viruses.<br />
                                <br />
                                12.2 You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.<br />
                                <br />
                                12.3 You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.<br />
                                <br />
                                13. LINKING TO OUR SITE<br />
                                <br />
                                13.1 You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.<br />
                                <br />
                                13.2 You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.<br />
                                <br />
                                13.3 You must not establish a link to our site in any website that is not owned by you.<br />
                                <br />
                                13.4 Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page without our prior written consent.<br />
                                <br />
                                13.5 We reserve the right to withdraw linking permission without notice.<br />
                                <br />
                                13.6 The website in which you are linking must comply in all respects with the content standards set out in these terms and conditions.<br />
                                <br />
                                13.7 If you wish to make any use of content on our site other than that set out above, please contact: info@mybeautysquad.com.<br />
                                <br />
                                14. THIRD PARTY LINKS AND RESOURCES IN OUR SITE<br />
                                <br />
                                14.1 Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only.<br />
                                <br />
                                14.2 We have no control over the contents of those sites or resources.<br />
                                <br />
                                15. APPLICABLE LAW<br />
                                <br />
                                15.1 Please note that these terms of use, its subject matter and its formation, are governed by English law. You and we both agree to that the courts of England and Wales will have non-exclusive jurisdiction. However, if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.<br />
                                <br />
                                16. CONTACT US<br />
                                <br />
                                16.1 To contact us, please email info@mybeautysquad.com.<br />
                                ​</p>

                                <h1>&nbsp;</h1>
                            </div>

                        </StyledCommon.Container>
                    </div>
                    <Footer />
                </StyledCommon.Wrapper >

            </div>
        </div>
    );

    onRedirect = () => {
        this.togglePopup();
        setTimeout(() => {
            this.toMain.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
        return false;
    };

    onSubmit = formValue => {
        let captchaMessage = '';
        if (this.state.captchaResponse == '') {
            captchaMessage = <div className="captcha-message">Please verify that you are a human!</div>
            this.setState({ captchaMessage: captchaMessage });
            return false;
        } else {
            this.setState({ captchaMessage: '' });
        }

        this.props.setLoader(true);
        let requiredChaeckbox = '';
        if (this.state.isChecked === false) {
            requiredChaeckbox = <span>Required</span>
            this.setState({ requiredChaeckbox: requiredChaeckbox })
            return false;
        }
        console.log(this.state.captchaResponse)
        let formValues = {
            'g-recaptcha-response': this.state.captchaResponse,
            user: {
                firstName: formValue.firstName,
                surname: formValue.surname,
                email: formValue.email,
                info: {
                    homeAddress: {
                        postcode: this.props.Information.postcode,
                        city: formValue.city,
                        address: this.props.Information.address
                    },
                    mobileNumber: formValue.mobileNumber,
                    countryCode: this.props.Information.countryCode,
                    country: this.props.Information.country
                }
            },
            appointment: {
                services: this.props.Information.services,
                isPeakTime: this.props.Information.isPeakTime,
                appointmentDateTime: this.props.Information.appointmentDateTime,
                quantity:this.props.Information.quantity
            }
        };

        this.setState({submitted:true});
        const response = this.props.bookingForm(this.props.setLoader, formValues);
        let errorMessage = ''

        response
            .then(result => {
                localStorage.removeItem('client_secret');
                localStorage.removeItem('access_token');
                localStorage.removeItem('booking_id');

                // if (this.props.appointmentData) {
                //     if (this.props.appointmentData.success == true) {
                //         localStorage.setItem('client_secret', this.props.appointmentData.data.paymentIntent.client_secret);
                //         localStorage.setItem('access_token', this.props.appointmentData.data.access_token);
                //         localStorage.setItem('booking_id', this.props.appointmentData.data.booking._id);
                //         this.props.history.push(PATHS.CHECKOUT);
                //     } else {
                //         errorMessage = this.props.appointmentData.message
                //     }
                //     this.setState({ errorMessage: errorMessage, submitted:false })
                // }
                if (this.props.appointmentData) {
                    if (this.props.appointmentData.success == true) {
                        const values = this.props.appointmentData.data;
                        const bookingIdArray = values &&values.booking.length> 0 &&values.booking.map(i=>i._id);
                        localStorage.setItem('client_secret', this.props.appointmentData.data.paymentIntent.client_secret);
                        localStorage.setItem('access_token', this.props.appointmentData.data.access_token);
                        localStorage.setItem('booking_id', this.props.appointmentData.data.booking._id);
                        // console.log(bookingIdArray,"bookingIdArray")
                        this.props.history.push(PATHS.CHECKOUT);
                    } else {
                        errorMessage = this.props.appointmentData.message
                    }
                    this.setState({ errorMessage: errorMessage, submitted:false })
                }
            })
            .catch(error => {
                //console.log(error)
                errorMessage = error;
                this.setState({ errorMessage: errorMessage, submitted:false })
            });

    };

    getValues = event => {
        this.setState({ name: event.target.value });
    };

    emailexistChange = event => {
        this.setState({ email: event.target.value });
        if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(event.target.value)) {
            const response = this.props.checkEmail(event.target.value);
        } else {
            this.props.resetEmail()
        }
    };

    isChecked = () => {
        if (this.state.isChecked === false) {
            this.setState({
                isChecked: true,
            })
        } else {
            this.setState({
                isChecked: false,
            })
        }
    }

    verifyCallback(response) {
        // console.log(response)
        if (response !== '') {
            this.setState({
                //  isVerified: true,
                captchaMessage: '',
                captchaResponse: response
            })
        } else {
            this.setState({
                captchaMessage: 'Could not varify captcha. Please refresh the page',
                captchaResponse: ''
            });
        }
    }

    render() {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }

        if (this.props.Information.length <= 0) {
            return <Redirect to={PATHS.HOME} />;
        }
        return (
            <StyledCommon.Wrapper>
                <Header />
                <Styled.BannerHome className="banner-bg">
                    <StyledCommon.Container className="pageContainer">
                        <Styled.BannerText className="pink-bg">
                            <Styled.BannerTextH1>Book Online</Styled.BannerTextH1>
                        </Styled.BannerText>
                    </StyledCommon.Container>
                </Styled.BannerHome>
                <div className="pageContainer pb-50">
                    <StyledCommon.Container className="main-wrapper">
                        <StyledCommon.Wrapper>
                            <StyledCommon.HeadingTag>
                                You can book your appointment below! To not miss out on coupon code and loyalty points please <a target="_blank" href='https://www.mybeautysquad.com/beautyapp' className="download-link">download our app</a> to book.
                            </StyledCommon.HeadingTag>
                        </StyledCommon.Wrapper>

                        <Styled.NotSure className="mt-30">
                            <form onSubmit={this.props.handleSubmit(this.onSubmit)} name="bookingForm">
                                <StyledCommon.BookForm>
                                    <div>
                                        <Field
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            validate={[maxLength250, required, email]}
                                            component={renderInput}
                                            onChange={this.emailexistChange}
                                        />
                                        <div className="email-exist">{this.props.checkEmailExist ? (this.props.checkEmailExist.success == true ? this.props.checkEmailExist.message: '') : ''}</div>
                                        {
                                            (this.props.checkEmailExist && this.props.checkEmailExist.message === 'Email already exists') ? '' : <div>
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    placeholder="First Name"
                                                    validate={[maxLength250, onlyChar]}
                                                    component={renderInput}
                                                    onChange={this.getValues}
                                                />

                                                <Field
                                                    name="surname"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    validate={[maxLength250, onlyChar]}
                                                    component={renderInput}
                                                    onChange={this.getValues}
                                                />

                                                <Field
                                                    name="mobileNumber"
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    validate={[maxLength250, required, number]}
                                                    component={renderInput}
                                                    onChange={this.getValues}
                                                />
                                            </div>
                                        }


                                        <Field
                                            name="city"
                                            type="text"
                                            placeholder="City"
                                            validate={[maxLength250, required, onlyChar]}
                                            component={renderInput}
                                            onChange={this.getValues}
                                        />

                                        <div className="terms-condition">
                                            <input type="checkbox" checked={(this.state.isChecked) ? true : false} onClick={this.isChecked} name="checkboxG1" id="checkboxG1" class="css-checkbox" />

                                            <label for="checkboxG1"
                                                class={this.state.requiredChaeckbox ? 'css-label error-checked' : 'css-label'}>
                                                I read and accept the&nbsp;
                                        <StyledCommon.InnerLink href={`#`} onClick={this.onRedirect}>

                                                    Terms & Conditions
                                            </StyledCommon.InnerLink>
                                            </label>
                                            {/* {this.state.requiredChaeckbox} */}
                                        </div>
                                        {this.state.showPopup ? this.popup() : null}
                                        <Recaptcha
                                            sitekey= {RECAPTCHA_PUBLIC_KEY}
                                            render="explicit"
                                            onloadCallback={this.recaptchaLoaded}
                                            verifyCallback={this.verifyCallback}
                                        />
                                        {this.state.captchaMessage}
                                    </div>
                                </StyledCommon.BookForm>

                                <div className="text-center">
                                    <StyledCommon.InnerSmallButton disabled={this.state.submitted} className="pink-btn no-hover btn-height d-inline-flex align-center just-cont-center p-5 mb-0 mt-5">
                                        Submit
                                    </StyledCommon.InnerSmallButton>
                                </div>
                                <div className="captcha-message">{this.state.errorMessage}</div>
                            </form>
                        </Styled.NotSure>
                    </StyledCommon.Container>
                </div>
                <Footer />
            </StyledCommon.Wrapper>
        );
    }
}
const mapStateToProps = state => {
    return {
        categoryList: '',
        appointmentData: state.content.getAppointment ? state.content.getAppointment.data : '',
        isLoading: state.content.isLoading,
        Information: state.content.getInformation,
        checkEmailExist: state.content.checkEmail ? state.content.checkEmail.data : ''

    };

}

export default connect(
    mapStateToProps,
    { setLoader, bookingForm, checkEmail, resetEmail }
)(
    reduxForm({
        form: 'bookingForm'
    })(Booking)
);
