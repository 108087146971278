import React, { Component } from 'react';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import * as Styled from '../page.styled';

/*
 TYPES
*/

type Props = {};
class LoaderScreen extends Component<Props> {
    render () {
        return (
            <Styled.loaderSpinner>
                <Styled.loaderSpinnerChild>
                    <Spinner color="#77bb1f" size={36} speed={1} animating={true} />
                </Styled.loaderSpinnerChild>
            </Styled.loaderSpinner>
        );
    }
}
export default LoaderScreen;
