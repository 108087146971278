// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Header } from '../includes';
import * as Styled from '../home/home.styled';
import * as StyledCommon from '../page.styled';

import * as image from '../../images';
import { PATHS } from '../../constants';

/*
 TYPES
*/

type Props = {
    success: Function,
    history: Object
};


 

class Thank extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            redirect: false
        };
    }

    componentDidMount(prevProps, prevState){
        ReactGA.initialize('AW-831985094', {
            debug: true,
            titleCase: true,
              gaOptions: {
                    send_to: 'AW-831985094/D89QCKaC_7ABEMar3IwD',
                    value: '0.0',
                    currency:'GBP'
              }
        });

    }

    onSubmit = () => {
        this.setState({
            redirect: true
        });
        //this.props.history.push(PATHS.HOME);
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            localStorage.clear();
            return <Redirect to={PATHS.HOME} />;
        }
    };


    render() {
        if (this.props.Information.length <= 0) {
            return <Redirect to={PATHS.HOME} />;
        }
        return (
            <StyledCommon.Wrapper>
                <Header />
                <Styled.BannerHome className="banner-bg">
                    <StyledCommon.Container className="pageContainer">
                        <Styled.BannerText className="pink-bg">
                            <Styled.BannerTextH1>Confirmed</Styled.BannerTextH1>
                        </Styled.BannerText>
                    </StyledCommon.Container>
                </Styled.BannerHome>
                <div className="pageContainer pb-50">
                    <StyledCommon.Container className="main-wrapper">
                        <StyledCommon.Wrapper>
                            <StyledCommon.Bannerthanks>
                                <img src={image.thankyou} />
                                <strong>Thank you!</strong>
                            </StyledCommon.Bannerthanks>
                            <StyledCommon.HeadingTag>{this.props.success ? this.props.success.message : ''}</StyledCommon.HeadingTag>
                        </StyledCommon.Wrapper>
                        <a
                            href="https://www.mybeautysquad.com/"
                            className="pink-btn no-hover btn-height d-inline-flex align-center just-cont-center p-5 mb-0 mt-5"
                        >
                            Go Back to Home
                        </a>
                        {this.renderRedirect()}
                    </StyledCommon.Container>
                </div>
            </StyledCommon.Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    success: state.content.paymentSuccess ? state.content.paymentSuccess.data : '',
    Information: state.content.getInformation
});

export default connect(mapStateToProps)(Thank);
