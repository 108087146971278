import * as styledComponents from 'styled-components';
import { Link } from 'react-router-dom';

const { default: styled } = styledComponents;

// Navbar css
export const Navbar = styled.div`
    background-color: #ffb6cf;
    text-align: center;
    & a {
        display: inline-block;
        padding: 10px;
        color: #fff;
        text-decoration: none;
`;

export const Wrapper = styled.div`
  display: block;
`;

// Container css

export const Container = styled.div``;

export const InnerContentPara = styled.p``;

export const Error = styled.span`
  color: #f00;
  width: 100%;
  display: block;
`;

export const Logo = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
`;

export const Banner = styled.div`
  position: relative;
`;

export const Button = styled.button`
  border: 1px solid green;
  box-shadow: 0px 0px 2px 0px;
  padding: 8px 16px;
  color: inherit;
  background-color: inherit;
  margin: 5px;
  width: 318px;
`;

export const InnerContentLink = styled(Link)``;

export const InnerSmallButton = styled.button``;

export const ViewMoreLink = styled(Link)`
  width: 100%;
`;

export const BottomBarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 15px;

  width: 100%;
  background: transparent;
  z-index: 9;

  a {
    font-size: 14px;
    font-weight: 300;
    color: #ffb6cf;
    text-decoration:underline;
    margin:0 5px;
    white-space:nowrap;
    &:hover {
    text-decoration:none;
    color:#000000;
    }  
    img {
      width: 150px;
      height: auto;
      @media (max-width: 400px) {
        width: 130px;
      }
  @media ( max-width:500px) {
        font-size:13px;
      }
    }
  }
  a.active {
    svg {
      color: #ffb6cf;
    }
  }
`;

export const phoneIcon = styled.div`
  svg {
    width: 35px;
    height: 32px;
    @media (max-width: 400px) {
      width: 28px;
      height: 28px;
    }
  }
`;

export const BarIcon = styled.div`
  svg {
    width: 35px;
    height: 35px;
    @media (max-width: 400px) {
      width: 28px;
      height: 28px;
    }
  }
`;
export const bottomlink = styled.svg`
  width: 40px;
  height: 40px;
  color: #ffb6cf;
  @media (max-width: 400px) {
    width: 35px;
    height: 35px;
  }
`;

export const Marker = styled.div`
  svg {
    width: 33px;
    height: 33px;
    @media (max-width: 400px) {
      width: 28px;
      height: 28px;
    }
  }
`;

export const FormLabel = styled.label`
  margin-bottom: 12px;
  color: #57585b;
  display: flex;
  font-weight: 400;
`;

export const FormInput = styled.input`
  height: 46px;
  width: 100%;
`;

export const FormTextarea = styled.textarea`
  width: 100%;
`;

export const FormSelect = styled.select`
  -webkit-appearance: button;
  -webkit-padding-end: 20px;
  -webkit-padding-start: 2px;
  -webkit-user-select: none;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const FormDatePicker = styled.div`
  height: 54px;
  border-radius: 0;
  border: solid 1px #a8a8a8;
  margin-bottom: 30px;
  color: #ffb6cf;
`;

export const error = styled.div`
  color: red;
  margin-bottom: 10px;
  margin-top: -8px;
  font-size: 12px;
`;

export const loaderSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`;

export const loaderSpinnerChild = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  position: relative;

  &:before {
    content: '';
    // font-family: 'Font Awesome 5 Pro';
    position: absolute;
    background: #fff url('arrow-drop.png') no-repeat center center;
    top: 1px;
    right: 1px;
    width: 50px;
    height: 90%;
    font-weight: 600;
    text-align: center;
    color: #ffb6cf;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
  }
`;

export const InnerBanner = styled.div`
  position: relative;
`;
export const InnerBannerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InnerPageHeading = styled.div`
  position: absolute;
  bottom: -25px;
  padding: 0 20px 0 0;
  width: 100%;
  left: 0;
  text-align: left;
  vertical-align: top;
  h2 {
    background: #e9e9e9;
    text-align: left;
    padding: 20px;
    display: inline-block;
  }
`;

export const InnerPageHead = styled.div`
  bottom: -25px;
  padding: 0 20px 0 0;
  width: 100%;
  left: 0;
  text-align: left;
  vertical-align: top;
  h2 {
    background: #e9e9e9;
    text-align: left;
    padding: 20px;
    display: inline-block;
  }
`;

export const InnerPageHeadingH2 = styled.h2``;

export const Heading3 = styled.h3``;

export const Heading4 = styled.h4``;

export const TopArrowLink = styled(Link)`
  position: absolute;
  left: 20px;
  top: auto;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  &.light-green {
    color: #adb73b !important;
  }
`;

export const InnerBannerPageContent = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InnerAnchor = styled.a`
  color: #ffb6cf;
  text-decoration: none;
`;

export const InnerLink = styled(Link)`
  color: #ffb6cf;
  text-decoration: none;
`;

export const LogoLink = styled(Link)``;

export const HeadingTag = styled.p`
  padding: 0 10px 15px 10px;
  text-align: center;
`;

export const BookForm = styled.div`
  .form-control {
  }
`;

export const Banner404 = styled.div`
  img {
    width: 100%;
    height: auto;
    opacity: 0.3;
  }
`;

export const Bannerthanks = styled.div`
  text-align: center;
  padding: 25px 0;
  img {
    width: 150px;
    height: auto;
    display: inline-block;
  }
  strong {
    display: block;
    padding-top: 20px;
    font-size: 20px;
  }
`;