export const SERVICES = 'SERVICES';
export const SET_LOADER = 'SET_LOADER';
export const CONTACT_FORM = 'CONTACT_FORM';
export const COUNTRY_NAME = 'COUNTRY_NAME';
export const TIME_SLOT = 'TIME_SLOT';
export const PEAK_TIME = 'PEAK_TIME';
export const APPOINTMENT = 'APPOINTMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILED = 'PAYMENT_FAILED';
export const CHECK_EMAIL = 'CHECK_EMAIL';
export const SELECTED_SERVICES = 'SELECTED_SERVICES';
export const SELECTED_PEAKPRICE = 'SELECTED_PEAKPRICE';
export const RESET_EMAIL = 'RESET_EMAIL';
export const SELECTED_ACTUALPRICE = 'SELECTED_ACTUALPRICE';
export const TOTALPRICE = 'TOTALPRICE';
    