import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import * as StyledCommon from '../page.styled';
import { paymentSuccess, paymentFailed, setLoader } from '../../actions';
import { connect } from 'react-redux';
import { PATHS } from '../../constants';
import { Redirect } from 'react-router-dom';
import { LoaderScreen, Header } from '../includes';

type Props = {
  paymentSuccess: Function,
  paymentFailed: Function,
  history: Object,
  stripe: Function,
  TotalAmount: Function,
  isLoading: boolean,
};

class CheckoutForm extends Component<Props> {
    constructor (props) {
        super(props);
        this.state = {
            complete: false,
            redirectSuccess: false,
            redirectFailed: false,
            cardError: '',
            paymentError: '',
            submitted:false
        };
        this.submit = this.submit.bind(this);
    }

    async submit () {
        this.props.setLoader(true);
        let cardError = this.state.cardError;
        let paymentError = '';
        if (this.state.cardElement._complete === false) {
            if (!this.state.cardElement._invalid) {
                cardError = 'Please fill the card details';
                this.setState({submitted:false, paymentError: cardError});
                 this.props.setLoader(false);
            }
        } else {
            cardError = '';
            let client_secret = localStorage.getItem('client_secret');
            if (!client_secret) {
                paymentError = 'Invalid request. Client secret not provided.';
                this.setState({submitted:false, paymentError: paymentError});
            } else {
                // call stripe api to make payment if card is valid
                if (!this.state.cardElement._invalid) {
                    this.setState({submitted:true});
                    const { paymentIntent, error } = await this.props.stripe.handleCardPayment(
                        client_secret,
                        this.state.cardElement
                    );

                    if (paymentIntent) {
                        if (paymentIntent.status == 'succeeded') {
                            localStorage.setItem('paymentMethodId', paymentIntent.source);
                            const response = this.props.paymentSuccess();
                            response
                                .then(result => {
                                    if (result.data.success == true) {
                                        localStorage.removeItem('client_secret');
                                        localStorage.removeItem('access_token');
                                        localStorage.removeItem('booking_id');
                                        localStorage.removeItem('booking_id');
                                        localStorage.clear();
                                        this.setState({ redirectSuccess: true });
                                    } else {
                                        paymentError = result.data.message;
                                        this.setState({submitted:false, paymentError: paymentError});
                                    }
                                })
                                .catch(error => {
                                    // console.log(error);
                                    this.props.setLoader(false);
                                    paymentError = 'Something went wrong. Server not responding.';
                                    this.setState({submitted:false, paymentError: paymentError});
                                });
                        }
                    } else {
                        // payment was failed from stripe
                        // console.log(error);
                        if (error.code == 'card_declined') {
                            paymentError = error.message;
                            this.setState({submitted:false, paymentError: paymentError});
                            this.props.setLoader(false);
                        } else {
                            paymentError = 'It seems like something went wrong. Your payment was failed';
                            this.setState({submitted:false, paymentError: paymentError});
                            this.props.setLoader(false);
                        }
                    }
                } else {
                    // card details are invalid
                    this.props.setLoader(false);
                    cardError = 'Card details are not valid';
                    this.setState({submitted:false, paymentError: cardError});
                }
            } // client secret ok
        } // card details are completed
       // this.setState({ cardError: cardError, paymentError: paymentError, submitted:false });
    }

    handleReady = element => {
        this.setState({ cardElement: element });
        let _this = this;
        element.addEventListener('change', function (e) {
            if (e.error) {
                
                _this.setState({ cardError: e.error.message });
            } else {
                _this.setState({ cardError: '' });
            }
        });
    };

    render () {
        if (this.props.isLoading) {
            return <LoaderScreen />;
        }
        let Total = '';
        if (this.props.getTotalPrice) {
            Total = this.props.getTotalPrice[0] ? this.props.getTotalPrice[0] : '';

            var encodedStr = this.props.getTotalPrice[1] ? this.props.getTotalPrice[1] : '';
            var parser = new DOMParser();
            var dom = parser.parseFromString('<!doctype html><body>' + encodedStr, 'text/html');
            var PriceSymbol = dom.body.textContent;
        }
        return (
            <div>
                {this.state.redirectSuccess == true ? <Redirect to={PATHS.THANK} /> : ''}
                {this.state.redirectFailed == true ? <Redirect to={PATHS.HOME} /> : ''}

                <CardElement onReady={this.handleReady} />
                <div className="red">{this.state.cardError}</div>
                <div className="text-center">
                    <StyledCommon.InnerSmallButton
                        onClick={this.submit}
                        className="pink-btn no-hover btn-height d-inline-flex align-center just-cont-center p-5 mb-0 mt-5"
                        disabled={this.state.submitted}
                    >
            Pay {PriceSymbol}
                        {Total}
                    </StyledCommon.InnerSmallButton>
                </div>
                <div className="red">{this.state.paymentError}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    TotalAmount: state.content.getAppointment ? state.content.getAppointment.data : '',
    isLoading: state.content.isLoading,
    getTotalPrice : state.content.totalPrice
});

export default connect(
    mapStateToProps,
    { paymentSuccess, paymentFailed, setLoader }
)(injectStripe(CheckoutForm));
